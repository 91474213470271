<template>
    <div class="page terms fade-in">
        <h1>{{ translate[language].title }} </h1>
        <div v-html="translate[language].content" />
    </div>
</template>
<script>
export default {
    data(){
        return{
            language : ( this.$cookies.get('language') == null  ? 'english'  : this.$cookies.get('language') ),
            translate : {
                spanish: {
                    title:"TÉRMINOS Y CONDICIONES",
                    content: "<p>La información que se expone en el presente espacio virtual, se ofrece únicamente como medio de consulta, más no como base para la realización de inversiones o movimientos financieros, por lo que HAN Capital, S.A.P.I. de C.V., (en adelante 'HAN') y sus accionistas no se hacen responsables directa o indirectamente de las operaciones financieras, ni de las acciones legales que las personas que la consultan llegasen a efectuar con base en dicha información.</p>"+
"<p> En algunas secciones de este Sitio es posible realizar comparaciones del comportamiento de diferentes instrumentos financieros en el tiempo, lo cual tampoco constituye una oferta, compra, venta, ni asesoría de inversión, por lo que HAN y sus accionistas no son responsables directa y/o indirectamente como lo establece el párrafo inmediato anterior.</p>"+
"<p>Asimismo, HAN y sus accionistas no son responsables directa o indirectamente por el tipo de contenido, por ningún tipo de errores o demoras en el contenido, su veracidad o por cualquier acción tomada en confianza de ello, así como por retrasos, interrupciones o errores, fallas u omisiones que sean ocasionados por o en la captura, proceso, transmisión, recepción o exhibición de la información, así como de su interpretación.</p>"+
"<p>La información que se expone en el presente Sitio, no garantiza la obtención del objetivo financiero, el comportamiento ni los rendimientos que se mencionan como valores de referencia, por lo que por ningún motivo puede considerarse que constituye sugerencia para la oferta, la compra o venta de valores.</p>"+
"<p>La participación en los proyectos inmobiliarios no deben ser al azar, razón por la que se recomienda que los potenciales inversionistas cuenten con la asesoría de expertos en la materia.</p>"+
"<p>El contenido de la presente página, ubicada en el Sitio web www.han.mx, no podrá ser reproducido con o sin fines de lucro, a menos que se cuente con la previa autorización por escrito de HAN, y en su caso, sólo podrá hacerse en los términos expresamente autorizados por ésta. En este caso, deberá mencionarse claramente la fuente y propiedad intelectual de la misma. Asimismo, queda expresamente prohibida la realización de acciones tales como, incluyendo sin limitar, copiar, hacer framing (uso de marcos para mostrar páginas de terceros), parsing (uso de algoritmos para extraer del código HTML de una página web, información propiedad de terceros), y/o cualquier otra mediante la cual se reproduzca todo o parte del contenido del Sitio, de cualquier forma y/o para cualquier fin.</p>"+
"<p>Los interesados en obtener información detallada sobre el contenido y/o respecto de las autorizaciones para la reproducción de esta publicación electrónica, deberán comunicarse a la Dirección Jurídica y Corporativa de 'HAN', al teléfono +52 55 16598041.</p>"+
"<p>HAN Capital, HAN, Here & Now y todos los demás logotipos y/o marcas de este Sitio, son marcas registradas ante el Instituto Mexicano de la Propiedad Industrial y constituyen a su titular como propietario exclusivo de los derechos que derivan de ellas, de conformidad con lo establecido en la Ley de la Propiedad Industrial.</p>"+
"<p>Este Sitio podrá tener conexiones con sitios controlados por terceros ajenos a HAN, previa autorización por escrito de esta última. Por tanto, HAN y sus accionistas no se hacen responsables directa o indirectamente por sus contenidos, así como por los servicios que brinden dichos sitios. Es importante señalar que el hecho de que exista la conectividad con esos sitios no implica la existencia de alguna asociación y/o participación entre estos y HAN.</p>"+
"<p>HAN y sus accionistas no se harán responsables directa o indirectamente frente a terceros, del contenido del material publicitario que sea incluido en el Sitio por los anunciantes, así como de cualquier derecho, interés u obligación que del mismo se deriven, incluyendo de manera enunciativa mas no limitativa: marcas, nombres y avisos comerciales, reservas de títulos, diseños, formatos o sistemas publicitarios protegidos de conformidad con la ley.</p>"+
"<p>Las personas que consulten y/o utilicen la presente página electrónica, por el simple hecho de hacerlo, aceptan y se obligan a los términos y condiciones de responsabilidad aquí contenidas.</p>"+
"<p>HAN y sus accionistas se reservan el derecho de modificar el contenido del presente Sitio en cualquier momento sin previo aviso, y sin responsabilidad alguna a su cargo."+
"<p>HAN Capital Here & Now es una marca registrada.</p>"
,
                },
                english : {
                    title: "Terms and Conditions",
                    content: "<p>The information stated in this virtual space, is offered only as a means of consult, not as basis for the performance of investments or financial movements, therefore HAN Capital, S.A.P.I. de C.V. (hereinafter HAN') and its shareholders  are not directly or indirectly responsible for the financial transactions, nor for the legal actions that the persons consulting might perform based on such information.</p>" +
"<p> In some sections of this Site it is possible to make comparisons between the behavior of different financial instruments in time, which does not constitute an offer, purchase, sale, nor investment advisory, therefore HAN and its shareholders are not directly or indirectly responsible, as provided in the immediately above paragraph.</p>" +
"<p> Likewise, HAN and its shareholders are not directly or indirectly responsible for the type of content, for any type of errors or delays in content, its veracity or for any action taken relying on it, as well as for delays, interruptions or errors, flaws or omissions that are caused by or in the capturing, processing, transmission, reception or display of the information, as well as from its interpretation.</p>" +
"<p> The information that is displayed in this Site, does not guarantee the attainment of financial targets, the behavior nor yields that are mentioned as baseline, therefore in no event should these be deemed to constitute suggestions for the offer, purchase or sale of securities.</p>" +
"<p> Participation in real-estate projects should not be random, therefore it is recommended that the potential investors who wish to perform investments in real-estate projects, have the advisory from experts in the subject to understand the inherent risks of these type of projects.</p>" +
"<p> The content of this page, located in the web Site www.han.mx may not be reproduced whether for profit or non-profit, unless it has prior written authorization from HAN, and if applicable, may only be do so under the terms expressly authorized by the latter. In this event, it shall clearly state the source and intellectual ownership thereof. Likewise, it is expressly prohibited the performance of actions such as, including without limitation, copy, framing (use of frames to show third party pages), parsing (use of algorithms to extract the HTML code from a web page, third party information), and/or any other whereby the content of the Site is reproduced in whole or in part, in any way and/or for any purpose. The parties interested in attaining detail information regarding the content and/or with respect to authorizations for the reproduction of this electronic publication, shall contact the Legal and Corporate Directorate of “HAN”, at the phone number +52 55 16598041.</p>" +
"<p> HAN Capital, HAN, Here & Now and all other logos and/or brands of this Site, are trademarks before the Mexican Institute of Industrial Property and constitute their holder as sole owner of the rights derived therefrom, pursuant to the provisions of the Industrial Property Law.</p>" +
"<p> This Site may have connections with sites controlled by third parties alien to HAN, prior written authorization from the latter. Therefore, HAN and its shareholders are not directly or indirectly responsible for their content, as well as for the services provided by such sites. It is important to point out that the fact that there is a connection with these sites does not imply the existence of any association and/or interest among these and HAN.</p>" +
"<p> HAN and its shareholders shall not be directly or indirectly responsible before third parties, for the content of the advertisement material that is included in the Site by advertisers, as well as of nay right, interest or obligation derived therefrom, including enunciatively but not limitatively: brands, names and commercial Ads, title reserves, designs, formats or advertisement systems protected under the law.</p>" +
"<p> The persons that consult and/or use this electronic page, by doing so, agree and are bound by the terms and conditions of responsibility contained herein.</p>" +
"<p> HAN and its shareholder reserve the right to modify the content of this Site at any time without prior notice, and without any liability."+
"<p>HAN Capital Here & Now is a registered brand.</p>"
                }
            }
        }
    },
    methods: {
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  ) 
            }, 1000 )
        },
    },
    created(){
       this.checkLanguage()
    }
}
</script>
